import styled from '@emotion/styled';

import variables from 'theme/styles/vars';

export default styled.div`
    position: relative;
    width: 100%;
    padding: ${variables.sectionPadding};

    @media all and (max-width: ${variables.tabletM}) {
        .layout-container {
            width: 100%;

            .headline {
                padding: 0 5%;
            }
        }
    }

    @media all and (max-width: ${variables.mobileL}) {
        padding: ${variables.sectionPaddingMobile};
        overflow-x: hidden;
    }

    @media all and (max-width: ${variables.mobileM}) {
        .benefits-boxes {
            .box-copy {
                .title {
                    font-size: 18px;
                }

                .subtitle {
                    font-size: 16px;
                }
            }
        }
    }
`;
