import Element from 'types/redux/Element';
import ListCollection from 'types/redux/ListCollection';

import Article from 'models/Article';
import CmsPage from 'models/CmsPage';
import Opinion from 'models/Opinion';
import Product, { Genders, ProductTypes, Visibilities } from 'models/Product';

import { ELEMENT_MARKUP, LIST_MARKUP } from 'consts/redux';

import { list as listArticles } from 'requests/articles';
import { single as singleCmsPage } from 'requests/cmsPages';
import { list as listOpinions } from 'requests/opinions';
import { list as listProducts } from 'requests/products';

import Component from './component';
import { NextPageContextWithCookies, Props } from './types';

Component.getInitialProps = async (appContext: NextPageContextWithCookies): Promise<Props> => {
    const gender = appContext.req?.cookies?.gender;
    let articles: ListCollection<Article> = LIST_MARKUP;
    let opinions: ListCollection<Opinion> = LIST_MARKUP;
    let products: ListCollection<Product> = LIST_MARKUP;
    let cmsPage: Element<CmsPage> = ELEMENT_MARKUP;

    try {
        await Promise.all([
            singleCmsPage({ slug: 'home' }),
        ]).then(response => {
            cmsPage = response[0];
        });
    } catch (error) {
        console.error(error);
    }

    try {
        await Promise.all([
            listArticles({ page: 1, perPage: 3 }),
            listOpinions({ page: 1, perPage: 9 }),
            listProducts({
                page: 1,
                perPage: 24,
                gender: gender || Genders.Female,
                visibility: Visibilities.Homepage,
                type: ProductTypes.Package,
            }),
        ]).then((responses) => {
            articles = responses[0];
            opinions = responses[1];
            products = responses[2];
        });
    } catch (error) {
        console.error(error);
    }

    return {
        articles: articles.elements || [],
        opinions: opinions.elements || [],
        products: products || null,
        cmsPage: cmsPage || null,
        initialGender: gender || Genders.Female,
    };
};

export default Component;
