import styled from '@emotion/styled';

import variables from 'theme/styles/vars';

export default styled.div`
    position: relative;
    width: 100%;
    padding: ${variables.sectionPadding};

    .public-layout-description {
        .copy {
            .description {
                margin-top: 2em;
            }
        }

        .children {
            margin: 0;

            .section-list {
                .public-layout-sections-description-two-col {
                    .list {
                        .public-layout-sections-blocks-two-col {
                            justify-content: center;

                            .left-col {
                                width: 100%;
                                text-align: center;
                            }

                            .right-col {
                                width: 0;
                            }
                        }

                        > * {
                            &:nth-of-type(odd) {
                                flex-direction: row-reverse;
                            }
                        }
                    }
                }
            }

            .section-control {
                padding-top: 2em;

                .expand-state {
                    .button-icon-after {
                        transform: scaleY(-1);
                    }
                }
            }
        }
    }

    @media all and (max-width: ${variables.tabletS}) {
        .public-layout-description {
            .children {
                .section-list {
                    .public-layout-sections-description-two-col {
                        .list {
                            .public-layout-sections-blocks-two-col {
                                flex-direction: column;
                            }
                        }
                    }
                }
            }
        }
    }

    @media all and (max-width: ${variables.mobileL}) {
        padding: ${variables.sectionPaddingMobile};
    }
`;
