import styled from '@emotion/styled';

import variables from 'theme/styles/vars';

export default styled.div`
    position: relative;
    background: transparent;
    width: 100%;
    cursor: default;

    .headline {
        font-size: 1.7em;
        font-weight: 700;
    }

    .subheadline {
        font-size: 1.9em;
        font-weight: 400;
    }

    .description {
        margin-top: 1em;
        font-size: 1em;
    }

    .link {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        text-decoration: none;
        padding-top: .5em;

        .layout-button {
            justify-content: flex-start;

            .button {
                .button-content {
                    font-size: .9em;
                    color: #BA9449;
                    text-transform: uppercase;
                    letter-spacing: .3em;
                }
            }
        }
    }

    @media all and (max-width: ${variables.mobileL}) {
        &.layout-left {
            text-align: left;

            .headline {
                text-align: left;
            }

            .subheadline {
                text-align: left;
            }

            .link {
                justify-content: center;
            }
        }

      .control {
          font-size: 130%;
      }
    }
    @media all and (max-width: ${variables.mobileM}) {
    }
`;
