import { FunctionComponent } from 'react';

import { ButtonElements, ButtonIconPositions, ButtonIconTypes, ButtonVariants } from 'components/layout/Button';
import LayoutContainer from 'components/layout/LayoutContainer';
import ZoneBox from 'components/modules/public/layout/boxes/ZoneBox';
import DescriptionBlock from 'components/modules/public/layout/sections/blocks/Description';

import StyledComponent from './styles';
import { Props } from './types';

const PublicPageHomeSectionZone: FunctionComponent<Props> = ({ descriptionBlockProps, boxes }) => {
    return (
        <StyledComponent className="public-page-home-section-zone">
            <LayoutContainer>
                <div className="section">
                    <DescriptionBlock
                        buttonProps={{
                            children: descriptionBlockProps.button.label,
                            element: ButtonElements.Button,
                            variant: ButtonVariants.Outline,
                            className: 'arrow',
                            icon: {
                                value: '/images/home/arrow.svg',
                                position: ButtonIconPositions.After,
                                type: ButtonIconTypes.Image,
                            },
                        }}
                        {...descriptionBlockProps}
                    />
                    <div className="zone-boxes">
                        <ZoneBox {...boxes?.woman} />
                        <ZoneBox {...boxes?.man} />
                    </div>
                </div>
            </LayoutContainer>
        </StyledComponent>
    );
};

export default PublicPageHomeSectionZone;
