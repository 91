import { useContext } from 'react';
import { NextPage } from 'next';

import CmsSection from 'models/CmsSection';
import { Visibilities } from 'models/Product';

import LocaleContext from 'components/context/Locale';
import Head from 'components/layout/Head';
import PageContainer from 'components/layout/PageContainer';
import SectionsWrapper from 'components/modules/public/cms/SectionsWrapper';
import SectionWrapper from 'components/modules/public/cms/SectionWrapper';
import WrapperPublic from 'components/wrappers/Public';

import SectionBenefits from './sections/Benefits';
import SectionBlogs from './sections/Blogs';
import SectionCheckout from './sections/Checkout';
import SectionContact from './sections/Contact';
import SectionHero from './sections/Hero';
import SectionLearnMore from './sections/LearnMore';
import SectionMap from './sections/Map';
import SectionNews from './sections/News';
import SectionOpinions from './sections/Opinions';
import SectionProducts from './sections/Products';
import SectionZone from './sections/Zone';
import StyledComponent from './styles';
import { Props } from './types';

const PublicPageHome: NextPage<Props> = ({ articles, opinions, products, cmsPage, initialGender }) => {
    const localeContext = useContext(LocaleContext);
    const translations = localeContext?.translations?.home;

    const sections = [
        {
            slug: 'hero',
            order: 1,
            visible: true,
            children: (slug: string, cmsSection: CmsSection) => (
                <SectionWrapper
                    cmsSection={cmsSection}
                    key={slug}
                >
                    {(locale, override) => (
                        <SectionHero
                            translations={{
                                ...translations?.content?.hero,
                                ...locale,
                            }}
                            override={override}
                        />
                    )}
                </SectionWrapper>
            ),
        },
        {
            slug: 'zones',
            order: 2,
            visible: true,
            children: (slug: string, cmsSection: CmsSection) => (
                <SectionWrapper
                    cmsSection={cmsSection}
                    key={slug}
                >
                    {(locale) => (
                        <SectionZone
                            {...translations?.content?.zone}
                            descriptionBlockProps={{
                                headline: locale?.headline,
                                button: {
                                    label: locale?.descriptionBlockPropsButtonLabel,
                                    href: locale?.descriptionBlockPropsButtonHref,
                                },
                            }}
                            boxes={{
                                woman: {
                                    button: {
                                        href: locale?.womanBoxButtonHref,
                                        label: locale?.womanBoxButtonLabel,
                                    },
                                    image: locale?.womanBoxImage?.imageUrl,
                                    title: locale?.womanBoxTitle,
                                },
                                man: {
                                    button: {
                                        href: locale?.manBoxButtonHref,
                                        label: locale?.manBoxButtonLabel,
                                    },
                                    image: locale?.manBoxImage?.imageUrl,
                                    title: locale?.manBoxTitle,
                                },
                            }}
                            {...locale}
                        />
                    )}
                </SectionWrapper>
            ),
        },
        {
            slug: 'benefits',
            order: 3,
            visible: true,
            children: (slug: string, cmsSection: CmsSection) => (
                <SectionWrapper
                    cmsSection={cmsSection}
                    key={slug}
                >
                    {(locale, override) => (
                        <SectionBenefits
                            translations={{
                                ...translations?.content?.benefits,
                                ...locale,
                            }}
                            override={override}
                        />
                    )}
                </SectionWrapper>
            ),
        },
        {
            slug: 'offers',
            order: 4,
            visible: Array.isArray(products.elements) && products.elements.length > 0,
            children: (slug: string, cmsSection: CmsSection) => (
                <SectionWrapper
                    cmsSection={cmsSection}
                    key={slug}
                >
                    {(locale, override) => {
                        return (
                            <SectionProducts
                                translations={{
                                    ...translations?.content?.products,
                                    ...locale,
                                }}
                                override={() => override({
                                    initialGender,
                                    products,
                                    visibility: Visibilities.Homepage,
                                    sectionSettings: cmsSection?.sectionSettings,
                                })}
                            />
                        );
                    }}
                </SectionWrapper>
            ),
        },
        {
            slug: 'news',
            order: 5,
            visible: true,
            children: (slug: string, cmsSection: CmsSection) => (
                <SectionWrapper
                    cmsSection={cmsSection}
                    key={slug}
                >
                    {(locale, override) => (
                        <SectionNews
                            locale={locale}
                            override={override}
                        />
                    )}
                </SectionWrapper>
            ),
        },
        {
            slug: 'learnMore',
            order: 6,
            visible: true,
            children: (slug: string, cmsSection: CmsSection) => (
                <SectionWrapper
                    cmsSection={cmsSection}
                    key={slug}
                >
                    {(locale, override) => (
                        <SectionLearnMore
                            locale={locale}
                            override={override}
                        />
                    )}
                </SectionWrapper>
            ),
        },
        {
            slug: 'opinions',
            order: 7,
            visible: Array.isArray(opinions) && opinions.length > 0,
            children: (slug: string, cmsSection: CmsSection) => (
                <SectionWrapper
                    cmsSection={cmsSection}
                    key={slug}
                >
                    {(locale, override) => (
                        <SectionOpinions
                            translations={{
                                ...translations?.content?.opinions,
                                ...locale,
                            }}
                            override={() => override({
                                opinions,
                            })}
                        />
                    )}
                </SectionWrapper>
            ),
        },
        {
            slug: 'map',
            order: 8,
            visible: true,
            children: (slug: string, cmsSection: CmsSection) => (
                <SectionWrapper
                    cmsSection={cmsSection}
                    key={slug}
                >
                    {(locale, override) => (
                        <SectionMap
                            translations={{
                                ...translations?.content?.map,
                                ...locale,
                            }}
                            override={override}
                        />
                    )}
                </SectionWrapper>
            ),
        },
        {
            slug: 'blogs',
            order: 9,
            visible: Array.isArray(articles) && articles.length > 0,
            children: (slug: string, cmsSection: CmsSection) => (
                <SectionWrapper
                    cmsSection={cmsSection}
                    key={slug}
                >
                    {(locale, override) => (
                        <SectionBlogs
                            translations={{
                                ...translations?.content?.blogs,
                                ...locale,
                            }}
                            override={() => override({
                                articles,
                            })}
                        />
                    )}
                </SectionWrapper>
            ),
        },
        {
            slug: 'contact',
            order: 10,
            visible: true,
            children: (slug: string, cmsSection: CmsSection) => (
                <SectionWrapper
                    cmsSection={cmsSection}
                    key={slug}
                >
                    {(locale, override) => (
                        <SectionContact
                            locale={locale}
                            override={override}
                        />
                    )}
                </SectionWrapper>
            ),
        },
        {
            slug: 'checkout',
            order: 11,
            visible: true,
            children: (slug: string, cmsSection: CmsSection) => (
                <SectionWrapper
                    cmsSection={cmsSection}
                    key={slug}
                >
                    {(locale, override) => (
                        <SectionCheckout
                            override={override}
                            locale={locale}
                        />
                    )}
                </SectionWrapper>
            ),
        },
    ];

    return (
        <WrapperPublic
            topbarScrollEffect
            isBannerVisible={cmsPage?.data?.isBannerVisible}
            bannerContent={cmsPage?.data?.bannerContent}
            bannerLink={cmsPage?.data?.bannerLink}
        >
            <StyledComponent className="public-page-home">
                <Head
                    title={cmsPage?.data?.locale?.title || translations?.meta?.title}
                    description={cmsPage?.data?.locale?.description || translations?.meta?.description}
                    keywords={cmsPage?.data?.locale?.keywords || translations?.meta?.keywords}
                    tags={cmsPage?.data?.head}
                    cmsPageIndexingEnabled={cmsPage?.data?.indexingEnabled}
                />
                <PageContainer>
                    <SectionsWrapper
                        sections={sections}
                        cmsSections={cmsPage?.data?.sections}
                    />
                </PageContainer>
            </StyledComponent>
        </WrapperPublic>
    );
};

export default PublicPageHome;
