import styled from '@emotion/styled';

import variables from 'theme/styles/vars';

export default styled.div`
    position: relative;
    width: 100%;
    padding: ${variables.sectionPadding};
    padding-top: 8em;

    .public-layout-sections-blocks-description {
        .link {
            .layout-button {
                .button {
                    .button-content {
                        color: #956f26;
                    }
                }
            }
        }
    }

    &:hover {
        .public-layout-sections-blocks-description {
            .link {
                .layout-button {
                    .button {
                        .button-content {
                            color: #CFB799;
                        }

                        .button-icon-after {
                            transform: translateX(20%);
                        }
                    }
                }
            }
        }
    }

    .section {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .public-layout-sections-blocks-description {
            width: 30%;

            .link {
                .layout-button {
                    .button {
                        cursor: default;

                        .button-content {
                            cursor: default;
                        }
                    }
                }
            }
        }

        .zone-boxes {
            width: 70%;
            display: flex;
            justify-content: space-around;
            align-items: center;
            gap: 1em;

            > * {
                width: 48%;

                &:last-of-type {
                    margin-right: 0;
                }
            }
        }
    }

    @media all and (max-width: ${variables.tabletL}) {
        .section {
            flex-direction: column;
            align-items: center;

            .public-layout-sections-blocks-description {
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                .headline {
                    br {
                        display: none;
                    }
                }

                .link {
                    padding-top: 1em;

                    .layout-button {
                        .button {
                            .button-content {
                                cursor: initial;
                            }

                            .button-icon-after {
                                display: none;
                            }
                        }
                    }
                }
            }

            .zone-boxes {
                justify-content: center;
                width: 100%;
                gap: 2em;
                margin-top: 2em;

                > * {
                    width: 100%;
                    max-width: 380px;
                }
            }
        }
    }


    @media all and (max-width: ${variables.tabletS}) {
        .section {
            .zone-boxes {
                flex-direction: column;
            }
        }
    }

    @media all and (max-width: ${variables.mobileL}) {
        padding: ${variables.sectionPaddingMobile};
    }
`;
