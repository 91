import styled from '@emotion/styled';

import variables from 'theme/styles/vars';

export default styled.div`
    position: relative;
    padding: ${variables.sectionPadding};

    .section-title {
        .headline {
            text-align: center;
            font-size: 1.7em;
            margin-bottom: 2em;
        }
    }

    .learn-more-list {
        > * {
            &:nth-of-type(even) {
                margin-top: 5em;
                flex-direction: row-reverse;
            }
        }

        .control {
            display: flex;
            margin-top: 2em;
        }
    }

    @media all and (max-width: ${variables.tabletL}) {
        .section-title {
            .headline {
                margin-bottom: 1em;
            }
        }

        .learn-more-list {
            > * {
                &:nth-of-type(even) {
                    margin-top: 5em;
                    flex-direction: column;
                }
            }

            .control {
                flex-direction: column;
                margin-top: 2em;
            }
        }
    }

    @media all and (max-width: ${variables.mobileL}) {
        padding: ${variables.sectionPaddingMobile};
    }
`;
