import { FunctionComponent } from 'react';

import StyledComponent from './styles';
import { Props } from './types';

const PublicPageHomeSectionNews: FunctionComponent<Props> = ({ override }) => {
    const hasOverride = override ? 'p-0' : '';

    return (
        <StyledComponent className={`public-page-home-section-news ${hasOverride}`}>
            {override()}
        </StyledComponent>
    );
};

export default PublicPageHomeSectionNews;
