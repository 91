import ApiOpinion from 'types/api/Opinion';

import Model from 'models/Model';

export default class Opinion implements Model {
    id: string;
    rating: number;
    author: string;
    content: string;

    constructor(data: ApiOpinion) {
        this.id = data.id;
        this.rating = data.rating;
        this.author = data.author;
        this.content = data.content;
    }
}