import { FunctionComponent } from 'react';

import { imageResizer } from 'utils/image';

import StyledComponent from './styles';
import { Props } from './types';

const PublicLayoutZoneBox: FunctionComponent<Props> = ({ button, image, title }) => {
    return (
        <StyledComponent
            className="public-layout-zone-box"
        >
            <a
                href={button?.href}
                className="link-wrapper"
            >
                <div className="box-content">
                    {title && (
                        <div
                            className="box-title"
                            dangerouslySetInnerHTML={{ __html: title }}
                        />
                    )}
                    {button && (
                        <div className="box-fake-button">{button.label}</div>
                    )}
                </div>
                <img
                    src={imageResizer(image, { width: 680, quality: 99 })}
                    srcSet={imageResizer(image, { width: 680, quality: 99 }, true)}
                    className="background-image"
                    loading="lazy"
                    alt={title}
                />
            </a>
        </StyledComponent>
    );
};

export default PublicLayoutZoneBox;
