import { FunctionComponent } from 'react';
import Link from 'next/link';

import Button, { ButtonElements } from 'components/layout/Button';
import HtmlStyledContent from 'components/layout/HtmlStyledContent';

import StyledComponent from './styles';
import { Props } from './types';

const PublicLayoutSectionsBlocksDescription: FunctionComponent<Props> = ({
    headline,
    subheadline,
    description,
    buttonProps,
}) => {
    const getButton = () => {
        let content = (
            <span className="link">
                <Button
                    element={ButtonElements.Anchor}
                    {...buttonProps}
                />
            </span>
        );

        if (buttonProps?.href) (
            content = (
                <Link
                    href={buttonProps?.href}
                    prefetch={false}
                >
                    {content}
                </Link>
            )
        );

        return content;
    };
    return (
        <StyledComponent className="public-layout-sections-blocks-description">
            <div
                className="headline"
                dangerouslySetInnerHTML={{ __html: headline }}
            />
            {subheadline && (
                <div
                    className="subheadline"
                    dangerouslySetInnerHTML={{ __html: subheadline }}
                />
            )}
            {description && (
                <HtmlStyledContent
                    className="description"
                    html={description}
                />
            )}
            {buttonProps && getButton()}
        </StyledComponent>
    );
};

export default PublicLayoutSectionsBlocksDescription;
