import styled from '@emotion/styled';

import variables from 'theme/styles/vars';

export default styled.div`
    padding: ${variables.sectionPadding};

    @media all and (max-width: ${variables.mobileL}) {
        padding: ${variables.sectionPaddingMobile};
    }
`;
